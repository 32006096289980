<template>
  <el-col class="page" >
    <el-col class="title">账户管理</el-col>
    <el-col class="pane-container">
      <el-col class="pane" @click.native="paneActive('1')">
        <el-button @click="drawer = true">添加账号</el-button>
      </el-col>
    </el-col>

    <el-col class="table-container">
      <el-table
          :data="tableData"
          border
          style="width: 100%"
          v-loading="loading">
        <el-table-column
            prop="companyName"
            label="企业名称">
        </el-table-column>
        <el-table-column
            prop="email"
            label="用户邮箱">
        </el-table-column>
        <el-table-column
            prop="phone"
            label="手机号">
        </el-table-column>
        <el-table-column
            prop="userSex"
            label="用户性别">
            <template slot-scope="scope">
                <span v-if="scope.row.userSex == 0">保密</span>
                <span v-if="scope.row.userSex == 1">男</span>
                <span v-if="scope.row.userSex == 2">女</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="userTrueName"
            label="真实姓名">
        </el-table-column>
        <el-table-column
            prop="isSystem"
            label="账号权限">
            <template slot-scope="scope">
                <span>{{ scope.row.isSystem === 1 ? '超级管理员' : '普通权限' }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="操作"
            align="center">
          <template slot-scope="scope">
            <el-button :disabled="scope.row.isSystem === 1" @click="removeUser(scope.row.userId)" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col style="margin-top: 20px">
      <Page
           :total="page.total"
           :current="page.pageNo"
           :page-size="page.pageSize"
           @on-page-size-change="onPageSizeChange"
           @on-change="changePage"
           show-total show-elevator style="font-size: 14px"></Page>
    </el-col>
    <el-drawer
        title="添加子账号"
        :visible.sync="drawer"
        direction="rtl"
        size="550px"
        class="drawer">
      <el-form :model="numberValidateForm" ref="numberValidateForm" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item
            label="邮箱"
            prop="email">
          <el-input type="addressee" v-model="numberValidateForm.email" autocomplete="off" placeholder="请输入收件人姓名"></el-input>
        </el-form-item>
        <el-form-item
            label="密码"
            prop="password">
          <el-input placeholder="请输入密码" v-model="numberValidateForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item
            label="手机号码"
            prop="phone">
          <el-input type="text" v-model.number="numberValidateForm.phone"
                    autocomplete="off" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item
            label="性别"
            prop="sex">
<!--          <el-input type="postalCode" v-model.number="numberValidateForm.postalCode" autocomplete="off" placeholder="请输入收件地址邮政编码"></el-input>-->
          <el-radio-group v-model="numberValidateForm.sex">
            <el-radio :label="0">保密</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
            label="真实姓名"
            prop="name">
          <el-input v-model="numberValidateForm.name" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('numberValidateForm')">提交</el-button>
          <el-button @click="resetForm('numberValidateForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-col>
</template>

<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
import {optionsData} from '../../../utils/china-area'
import api from '@/api/buyer/system/userApi.js'
import md5Js from '@/utils/md5'

Vue.use(iView)
export default {
  name: "address",
  data() {
    return {
      loading: true,
      active: 1,
      radio:3,
      drawer: false,
      direction: 'rtl',
      numberValidateForm: {
        age: '',
        addressee: '',
        describeAddress: '',
        postalCode: '',
          phone:'',
          password:'',
          email:'',
          sex: '',
          name: '',
      },
      rules: {
          email: [{required: true, message: '邮箱不能为空', trigger: 'change'}],
          password: [{required: true, message: '密码不能为空', trigger: 'change'}],
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'change' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
              if (!passwordreg.test(value)) {
                callback(
                    new Error(
                        '手机号码格式不正确'
                    )
                )
              } else {
                callback()
              }
            }
          }
        ],
          sex: [{required: true, message: '请选择性别', trigger: 'change'}],
          name: [{required: true, message: '请输入真实姓名', trigger: 'change'}],
      },
      tableData: [],
      options: optionsData(),
        page: {
          pageNo: 1,
            pageSize: 10,
            total: 0
        }
    }
  },
    mounted() {
        this.listUser();
    },
    methods: {
      listUser() {
          api.userList({
              pageNo: this.page.pageNo,
              pageSize: this.page.pageSize
          }).then(res => {
            this.timer = setTimeout(()=>{   //设置延迟执行
              this.loading = false
            },300)
              if(res.data) {
                  this.tableData = res.data.records;
                  this.page.total = res.data.total;
              }
          })
      },
        onPageSizeChange(val) {
          this.page.pageSize = val;
          this.listUser();
        },
        changePage(val) {
          this.page.pageNo = val;
          this.listUser();
        },
        removeUser(userId) {
          this.$confirm('确定要删除吗？', '删除提示').then(() => {
              api.deleteUser({
                  userId: userId
              }).then(res => {
                  if(res.code !== 200) {
                      this.buyerMsg(res.message, 'error');
                      return;
                  }
                  this.buyerMsg('删除成功');
                  this.page.pageNo = 1;
                  this.listUser();
              })
          })
        } ,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            let form = this.numberValidateForm;
          api.addUser({
              email: form.email,
              password: md5Js.hex_md5(form.password),
              phone: form.phone,
              userSex: form.sex,
              userTrueName: form.name
          }).then(res => {
              if(res.code !== 200) {
                  this.buyerMsg("增加用户失败", 'error');
                  return;
              }
              this.buyerMsg("增加用户成功");
              this.resetForm('numberValidateForm');
              this.drawer = false;
              this.page.pageNo = 1;
              this.listUser();
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    paneActive(id) {
      this.active = id;
    }
  },
  created() {
    // document.body.style.overflow='hidden'
  }
}
</script>

<style lang="less" scoped>

/deep/ .el-radio__input.is-checked .el-radio__inner{
  border-color:#F56E65;
  background:#F56E65;
}
/deep/ .el-radio__input.is-checked+.el-radio__label{
  color:#515a6e;
}

.page {
  margin: 20px 35px;
  min-width: 1130px;
  width: 97%;

.title {
color: #F56E65;
font-size: 24px;
letter-spacing: 1px;
margin-bottom: 40px;
}

.pane-container {
height: 34px;

.pane {
width: 48px;
height: 16px;
font-size: 16px;
margin-right: 40px;
color: rgba(65, 65, 65, 1);

/deep/ .el-button {
  color: #fff;
  background-color: #ed4014;
  border-color: #ed4014;
  height: 32px;
  width: 88px;
  padding: 8px 16px;
}

/deep/ .el-button:hover {
  color: #fff;
  background-color: #ee5630;
  border-color: #ee5630;
}
}

.pane:active {
color: #F56E65;
}

.pane:hover {
color: #F56E65;
}

.pane-active {
color: #F56E65;
}

}

.table-container {
margin-top: 20px;

/deep/ .el-table .cell, .el-table--border .el-table__cell:first-child .cell {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

/deep/ .el-table thead {
color: #515A6E;
}
}

/deep/ .ivu-page {
float: right;
margin-right: 143px;
}

/deep/ .el-drawer__header {
height: 52px;
line-height: 20px;
font-size: 16px;
color: #17233d;
font-weight: 500;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;

}

/deep/ .el-drawer__container {
margin-top: 58px;
}

.drawer {
/deep/ .el-form-item__label {
text-align: left;
margin-left: 20px;
width: 79px !important;
}

/deep/ .el-form-item__content {
margin-left: 100px !important;
width: 421px;
}

/deep/ .el-cascader {
width: 421px;
}
/deep/ .el-button--primary {
width: 90px;
height: 32px;
color: #fff;
background-color: #ed4014;
border-color: #ed4014;
padding-top: 9px;
}
/deep/ .el-button+.el-button {
margin-left: 35px;
width: 90px;
height: 32px;
padding-top: 9px;
}


}
}
</style>
