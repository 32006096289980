import {get,post} from "@/utils/axios"

const userList = p => get("/api-seller/apiconsole/user/getChildUser", p);

const addUser = p => post("/api-seller/apiconsole/user/syncChildUser", p);

const deleteUser = p => post("/api-seller/apiconsole/user/deleteUser", p)

export default {
    userList,
    addUser,
    deleteUser,
}